/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }
  .rc-form .file-upload input[type="file"] {
    display: none; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rc-rte h1,
.rc-rte h2,
.rc-rte h3,
.rc-rte h4,
.rc-rte h5,
.rc-rte h6 {
  text-transform: uppercase; }

.rc-rte .h1, .rc-rte h1 {
  color: #5A2700;
  font-weight: bolder;
  margin-bottom: 40px;
  font-size: 37px; }

.rc-rte .h2, .rc-rte h2 {
  color: #5A2700;
  font-weight: bolder;
  margin-bottom: 40px;
  font-size: 32px; }

.rc-rte .h3, .rc-rte h3 {
  color: #5A2700;
  font-size: 27px; }

.rc-rte .h4, .rc-rte h4 {
  color: #4F4E54;
  font-weight: bolder;
  font-size: 20px; }

.rc-rte .h5, .rc-rte h5 {
  color: #4F4E54;
  font-weight: bolder;
  margin-bottom: 30px;
  margin-top: 50px;
  font-size: 15px; }

.rc-rte p {
  color: #4F4E54; }

.rc-rte a {
  transition: color 0.5s ease;
  color: #4F4E54; }

.rc-rte a:hover {
  color: lightgray !important; }

.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

.rc-quote .blockquote {
  padding: .5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid rgba(90, 39, 0, 0.81);
  font-style: italic;
  font-family: "Times New Roman", sans-serif; }
  .rc-quote .blockquote footer {
    font-style: initial;
    font-size: 0.9em; }

.rc-image-slider img {
  height: 100%;
  width: 100%; }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
h1, h2, h3, h4, h5 {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto; }

.h1, h1 {
  color: #5A2700;
  font-weight: bolder;
  margin-bottom: 40px;
  font-size: 37px; }

.h2, h2 {
  color: #5A2700;
  font-weight: bolder;
  margin-bottom: 40px;
  font-size: 32px; }

.h3, h3 {
  color: #5A2700;
  font-size: 27px; }

.h4, h4 {
  color: #4F4E54;
  font-weight: bolder;
  font-size: 20px; }

.h5, h5 {
  color: #4F4E54;
  font-weight: bolder;
  margin-bottom: 30px;
  margin-top: 50px;
  font-size: 15px; }

p {
  color: #4F4E54; }

a {
  transition: color 0.5s ease;
  color: #4F4E54; }

a:hover {
  color: lightgray !important; }

/* buttons */
.btn-primary {
  background-color: #A43C00;
  color: white;
  border: none;
  border-radius: 0;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    background-color: #cd4900; }

/* forms */
/* nav */
.navbar {
  background-color: #ffffff;
  padding: 30px; }
  .navbar a {
    color: #514E53; }
  .navbar .nav-item {
    margin-left: 25px;
    font-size: 12px; }

.navbar-brand {
  position: relative;
  z-index: 12; }

.navbar-nav {
  position: relative;
  z-index: 11; }

.navbar-toggler {
  font-size: 1em;
  padding: 0;
  right: 0;
  top: 35px;
  border: none !important;
  outline: none !important;
  cursor: pointer; }

.navbar-toggler-icon {
  height: inherit;
  width: inherit;
  color: #5A2700;
  border: 1px solid #5A2700;
  padding: 10px 15px 10px 14px; }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

input:focus::-moz-placeholder {
  color: transparent; }

input:focus:-ms-input-placeholder {
  color: transparent; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "Muli", Arial, sans-serif;
  background-color: #FBF8F3;
  overflow: hidden;
  font-size: 0.9rem; }

/*
 *	UTILITIES
 */
.sr, .sr-left {
  visibility: hidden; }

.small-gutters {
  margin-left: -3px;
  margin-right: -3px; }

.small-gutters > .col, .small-gutters > [class*=col-] {
  padding-right: 3px;
  padding-left: 3px; }

/*
 *	GENERAL
 */
.wrapper {
  padding-top: 5em;
  padding-bottom: 3em;
  padding-left: 5em;
  padding-right: 5em; }

.active a {
  color: #fbcf98; }

/*
 * Body
 */
.wrapper--body {
  background-color: white; }

/*
 * Header
 */
.main-header {
  position: relative;
  z-index: 10;
  box-shadow: 0 17px 25px 0 rgba(0, 0, 0, 0.1);
  background-color: #FFFFFF; }
  .main-header .container {
    padding: 0; }

.logo__image {
  display: inline-block;
  vertical-align: bottom;
  height: 50px;
  position: relative;
  z-index: 12; }

.logo__text {
  display: inline-block;
  margin-left: 20px;
  font-size: 27px;
  color: #5A2700;
  vertical-align: bottom;
  position: relative;
  z-index: 12;
  font-weight: 900;
  line-height: 96%; }

.header-cta {
  background-color: #572700;
  position: absolute;
  right: 0;
  z-index: 10;
  font-weight: bold;
  font-size: 15px;
  line-height: 1;
  transition: background-color 0.5s ease; }
  .header-cta a {
    color: white;
    padding: 15px 40px 15px 40px;
    display: block; }
  .header-cta a:hover {
    text-decoration: none;
    color: white !important; }

.header-cta:hover {
  background-color: #743200; }

/*
 *  FEED
 */
.feed {
  margin-bottom: 30px; }

.feed__img a {
  display: block; }

.feed__img .feed__img-holder {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover; }

.feed__text {
  padding: 20px 0 0; }

/*
 * Home
 */
.home-slide__title {
  position: absolute;
  z-index: 2;
  bottom: 150px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4); }
  .home-slide__title .description {
    padding-top: 80px;
    padding-bottom: 35px;
    padding-left: 100px; }
    .home-slide__title .description p {
      color: #5A2700;
      font-size: 50px;
      font-weight: bold;
      line-height: 96%; }
    .home-slide__title .description p:last-child {
      margin-bottom: 0; }

.home-slide__image {
  width: 100%; }

.home-slider {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%; }

.home-slider__image {
  height: 140px;
  width: 140px;
  margin: 2.5px; }

/*
 * Assortiment
 */
.bestellen {
  width: 100%;
  height: 900px; }

/*
 * Contact
 */
.gmap {
  height: 500px;
  width: 100%;
  max-height: 90vh; }

/*
 * Feesttaarten
 */
.feesttaarten-titel {
  margin-top: 50px;
  margin-bottom: 50px; }

.feesttaarten-meer {
  display: block;
  text-align: center;
  color: #5A2700;
  margin-top: 25px; }

.taart {
  display: block;
  margin-bottom: 5px; }
  .taart img {
    height: 100%;
    width: 100%; }

/*
 * Nieuws
 */
.nieuws-items {
  max-width: 1200px; }
  .nieuws-items img {
    width: 300px;
    margin-bottom: 50px; }

/*
 * Footer
 */
.main-footer {
  background-color: #5A2700;
  color: white;
  font-size: 0.8em;
  padding: 2em 4em 2em 4em; }
  .main-footer p {
    color: white;
    margin: 0; }
  .main-footer a {
    color: white; }

.footer__social-wrapper div {
  vertical-align: top;
  display: inline-block;
  font-weight: bold; }

.footer__social-wrapper .icon {
  height: 44px;
  width: 44px;
  display: inline-block;
  margin-left: -15px;
  margin-top: -7px; }

.footer__social-wrapper .icon:first-child {
  margin-left: 1px; }

.footer__social-wrapper i {
  font-size: 2.5em; }

.post-footer {
  margin-top: 10px;
  margin-bottom: 100px;
  font-size: 0.8em; }
  .post-footer p {
    width: 100%;
    color: rgba(90, 39, 0, 0.5); }
  .post-footer a {
    color: rgba(90, 39, 0, 0.5); }
  .post-footer a:hover {
    color: #5A2700 !important; }

.footer__url {
  margin-right: 1em; }

/*
 *	SCROLLER
 */
.scroller {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 140px; }

.scroller__inner {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0;
  white-space: nowrap;
  transform: translate3d(0, 0, 0);
  animation: slideshow 90s linear infinite; }

.scroller__inner ul {
  overflow: hidden;
  display: inline-block;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  margin: 0; }

.scroller__inner li {
  display: inline-block;
  width: 140px;
  height: 100%;
  margin-right: 5px;
  font-size: 16px; }

.scroller__inner a {
  color: #939598; }

.scroller__inner a:hover,
.scroller__inner a:focus,
.scroller__inner a:active {
  color: #fff; }

.scroller__inner img {
  max-width: 100%; }

@keyframes slideshow {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-66.66%); } }

/*
 *	IMPORTS 
 */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 575px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media screen and (min-width: 1500px) {
  .container {
    width: 1450px; }
  .navbar .nav-item {
    margin-left: 50px; } }

@media screen and (max-width: 1199px) {
  .navbar .nav-item {
    margin: 0; }
  .home-slide__title .description {
    padding-top: 60px;
    padding-bottom: 15px;
    padding-left: 80px; }
    .home-slide__title .description p {
      font-size: 4vw; } }

@media screen and (max-width: 991px) {
  .navbar-collapse {
    position: inherit;
    text-align: right;
    width: 100%; }
  .align-items-end {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .logo__text {
    font-size: 3vw; }
  .home-slide {
    position: relative; }
  .logo__image {
    height: 6vw; }
  .home-slide__title {
    position: inherit;
    top: 0; }
    .home-slide__title p {
      margin-bottom: 0; }
    .home-slide__title .description {
      padding: 1em 2em 1em 2em !important;
      border-bottom: 2px solid rgba(90, 39, 0, 0.1); }
  .navbar-collapse {
    padding-top: 1em; }
  .nav-item {
    padding: 0;
    border-top: 1px solid lightgray; }
  .navbar-nav .nav-link {
    padding: 1em; }
  .nav-item:last-child {
    border-bottom: 1px solid lightgray; } }

@media screen and (max-width: 767px) {
  .home-slide__title .description {
    padding-top: 40px;
    padding-bottom: 15px;
    padding-left: 60px; }
    .home-slide__title .description p {
      font-size: 3vw; } }

@media screen and (max-width: 575px) {
  .logo__image {
    height: 30px; }
  .logo__text {
    margin-left: 5px;
    font-size: 16px; }
  .wrapper {
    padding-left: 1.5em;
    padding-right: 1.5em; }
  .navbar-collapse {
    margin-top: 1em; }
  .navbar-toggler {
    right: 30px;
    top: 32px; }
  .home-slide__title div {
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 30px; }
  .home-slide__title .description p {
    font-size: 20px; }
  .main-footer .col-auto {
    width: 100%;
    text-align: center; }
  .main-footer .text-uppercase {
    font-weight: bold; }
  .main-footer .footer__social-wrapper {
    margin-top: 1em;
    text-align: center !important; }
  .main-footer .footer__social-text {
    display: none; }
  .contact-wrapper {
    text-align: center; }
  .disclaimer-wrapper {
    display: block;
    width: 100%;
    text-align: center; }
  .nieuws-items img {
    display: block;
    margin: auto; }
  .h1, h1 {
    font-size: 25px;
    margin-bottom: 20px; }
  .h2, h2 {
    font-size: 21px;
    margin-bottom: 20px; }
  .h3, h3 {
    font-size: 18px; }
  .h4, h4 {
    font-size: 15px; }
  .h5, h5 {
    font-size: 12px;
    margin-bottom: 15px;
    margin-top: 20px; }
  .h6, h6 {
    font-size: 10px; } }

@media screen and (max-width: 400px) {
  .logo__text {
    display: none; }
  .home-slide__title {
    text-align: center; }
    .home-slide__title div {
      padding-top: 20px;
      padding-bottom: 9px;
      padding-left: 25px; }
  .footer__social-wrapper {
    text-align: center; }
  .container .container {
    /*padding-top: 5em;
    padding-bottom: 3em;
    padding-left: 2em;
    padding-right: 2em;*/ }
  .rc-rte .h1, .rc-rte h1 {
    margin-bottom: 30px;
    font-size: 32px; }
  .rc-rte .h2, .rc-rte h2 {
    margin-bottom: 30px;
    font-size: 27px; }
  .rc-rte .h3, .rc-rte h3 {
    font-size: 22px; }
  .rc-rte .h4, .rc-rte h4 {
    font-size: 19px; }
  .rc-rte .h5, .rc-rte h5 {
    margin-bottom: 20px;
    margin-top: 30px;
    font-size: 15px; } }

@media screen and (max-width: 330px) {
  .navbar-toggler {
    position: inherit;
    right: 0;
    width: 90%;
    margin: auto;
    padding: 0;
    display: block;
    padding-bottom: 3em; }
  .navbar-brand {
    height: 25px; }
  .navbar {
    -ms-flex-align: center !important;
    align-items: center !important;
    padding: 1em; }
  .navbar-collapse {
    text-align: center;
    padding-top: 1em; }
  .nav-item {
    border-top: 1px solid lightgray; }
  .nav-link {
    padding: 2em; }
  .nav-item:last-child {
    border-bottom: 1px solid lightgray; }
  .header-cta {
    width: 100%;
    text-align: center; }
  .carousel {
    padding-top: 45px; }
  .home-slide__image {
    height: 50vw; } }

@font-face {
  font-family: 'fontello';
  src: url("/font/fontello.eot?24689227");
  src: url("/font/fontello.eot?24689227#iefix") format("embedded-opentype"), url("/font/fontello.woff2?24689227") format("woff2"), url("/font/fontello.woff?24689227") format("woff"), url("/font/fontello.ttf?24689227") format("truetype"), url("/font/fontello.svg?24689227#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?24689227#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-facebook-squared:before {
  content: '\f308'; }

/* '' */

/*# sourceMappingURL=app.css.map */
